/**
 * Defines the data structures and functions for our map representation
 *
 * We're relying on cytoscape for all the rendering and graph algos so we will depend on it.
 * Cytoscape types: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/cytoscape/index.d.ts
 * */
import cytoscape, { Core, NodeDataDefinition, EdgeDataDefinition } from "cytoscape";
export const straightLineMap = [{ data: { id: 'W43' } },
                                { data: { id: 'W44' } },
                                { data: { id: 'W45' } },
                                { data: { id: 'W46' } },
                                { data: { id: 'W47' } },
                                { data: { id: 'e1', source: 'W43', target: 'W44', weight: 1 } },
                                { data: { id: 'e2', source: 'W44', target: 'W45', weight: 1 } },
                                { data: { id: 'e3', source: 'W45', target: 'W46', weight: 1 } },
                                { data: { id: 'e4', source: 'W46', target: 'W47', weight: 1 } }]

// Generate for all wards
// Blocks 4, 5, 6, 7
// Ward floors: 3 to 8
//
// // Connectivity of blocks
// 7
// 6
// 5 <- at level 7 -> 4
// All are connected via level 2
export let roughMap: any[] = []

function generateRoughMap() {
  roughMap = [];
  // Simple ward links
  for (let block = 4; block <= 7; block++) {
    for (let floor = 2; floor <= 8; floor++) {
      roughMap.push(genWardAsNode(block, floor))
      // Ward to ward links
      if (floor !== 8) roughMap.push(genWardToWardEdge(block, floor, block, floor + 1, 2))
    }
  }
  // Inter ward links (block 4 to 5)
  roughMap.push(genWardToWardEdge(4, 7, 5, 7, 2))
  // Level 2 connection
  roughMap.push(genWardToWardEdge(4, 2, 5, 2, 2))
  roughMap.push(genWardToWardEdge(4, 2, 6, 2, 2))
  roughMap.push(genWardToWardEdge(5, 2, 6, 2, 2))
  roughMap.push(genWardToWardEdge(6, 2, 7, 2, 2))
}
generateRoughMap();

export function wardToNodeIdString(block: number, floor: number) {
  return `W${block}${floor}`
}

export function genWardAsNode(block: number, floor: number) {
  return genNodeWithId(wardToNodeIdString(block, floor))
}

export function genNodeWithId(id: string) {
  return { data: { id } }
}

export function genWardToWardEdge(startBlock: number, startFloor: number, endBlock: number, endFloor: number, weight: number) {
  const startWardName = wardToNodeIdString(startBlock, startFloor)
  const endWardName = wardToNodeIdString(endBlock, endFloor)
  return genWeightedEdge(startWardName+endWardName, startWardName, endWardName, weight)
}

export function genWeightedEdge(id: string, source: string, target: string, weight: number) {
    return { data: { id, source, target, weight } }
}
