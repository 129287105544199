import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
//@ts-ignore
import COSEBilkent from 'cytoscape-cose-bilkent';
//@ts-ignore
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape, { Core, NodeDataDefinition, EdgeDataDefinition } from "cytoscape";
import * as Maps from '../modules/map'
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay-ts';
//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../modules/solver.js'
const solveWorker = worker();
console.log(solveWorker)



/* const createWorker = createWorkerFactory(() => import('../modules/solver.js')); */
cytoscape.use(COSEBilkent);
/* let solver: Solver | null = new Solver(); */

const App: React.FC = () => {
    const [inputWards, setInputWards] = useState("W43\nW44\nW45\nW46\nW47\nW48\nW52\nW53\nW54");
    const [startingLocation, setStartingLocation] = useState("W42");
    const [endingLocation, setEndingLocation] = useState("W78");
    const [route, setRoute] = useState<string[]>([]);
    const [routeErrors, setRouteErrors] = useState<string[]>([]);
    const [uniqueError, setUniqueError] = useState<boolean>(false);
    const [executionTime, setExecutionTime] = useState<number>(0);
    const [isSearchingForPath, setIsSearchingForPath] = useState<boolean>(false);

    // Create webworker solver
    /* const solveWorker = useWorker(createWorker); */

    const [cyInstance, setCyInstance] = useState<cytoscape.Core | null>(null);
    const [initialZoom, setInitialZoom] = useState<number>(0);

    const RenderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Please fill in the start location, end location, and wards to visit before finding the best route.
        </Tooltip>
    );

    const clearAllNotificationState = () => {
        setRouteErrors([])
        setUniqueError(false);
        setRoute([])
    }

    const handleSubmit = async () => {
        console.log("waiting for worker")
        await worker();
        console.log("Done")
        const start = performance.now();

        let allWards = [startingLocation]
        const wards = inputWards.split('\n').map((ward) => ward.trim());
        allWards = allWards.concat(wards, [endingLocation])


        if (cyInstance === null) {
            alert("Graph not instantiated!")
            return;
        }

        // Validation
        console.log("Awaiting async validation from webworker...")
        const result = await solveWorker.validate(cyInstance.elements().jsons(), allWards)
        if (!result.ok) {
            if (result.error.error === "missing") {
                clearAllNotificationState()
                //@ts-ignore
                setRouteErrors(result.error.details.map(v => v.nodeId))
            } else if (result.error.error === "unique") {
                clearAllNotificationState()
                setUniqueError(true);
            }
            return
        } else {
            setRouteErrors([])
            setUniqueError(false);
        }

        // Clear the route - this works because we have an await-point below, so react can propagate the updates
        setRoute([]);
        setIsSearchingForPath(true)

        // Solving
        console.log("Starting path solve")
        const shortestPath = await solveWorker.solve(cyInstance.elements().jsons(), allWards)

        setIsSearchingForPath(false)

        /* for (let pathProgress of shortestPath) {

         * } */
        if (shortestPath.ok) {
            setRoute(shortestPath.value);
        }

        console.log("Done!")
        const end = performance.now();
        console.log(`Execution time: ${end - start} ms`);
        setExecutionTime(end-start)
    };
    const layout = {
        name: 'random'
    }
    const gridLayout = {
        name: 'grid',
        rows: 4,
    }
    const coseBilkentLayout = { name: 'cose-bilkent',  };
    const currentLayout = coseBilkentLayout;
    const resetLayout = () => {
        if (cyInstance) {
            cyInstance.layout(currentLayout).run();
        }
    };

    const resetGraph = () => {
        if (cyInstance) {
            cyInstance.reset();
            cyInstance.zoom(initialZoom);
            resetLayout();
        }
    };

    const zoomIn = () => {
        if (cyInstance) {
            cyInstance.zoom(cyInstance.zoom() * 1.2);
        }
    };

    const zoomOut = () => {
        if (cyInstance) {
            cyInstance.zoom(cyInstance.zoom() / 1.2);
        }
    };

    const highlightStart = (str: string) => {
        if (cyInstance === null) return;
        /* cyInstance?.$(`node#${str}`).style({"overlay-color": "red", "overlay-opacity": "0.5", "overlay-padding": "5px"}) */
        cyInstance?.$(`node.starting_node`).removeClass("starting_node")
        cyInstance?.$(`node#${str}`).addClass("starting_node")
    }

    const highlightEnd = (str: string) => {
        if (cyInstance === null) return;
        /* cyInstance?.$(`node#${str}`).style({"overlay-color": "red", "overlay-opacity": "0.5", "overlay-padding": "5px"}) */
        cyInstance?.$(`node.ending_node`).removeClass("ending_node")
        cyInstance?.$(`node#${str}`).addClass("ending_node")
    }

    const highlightPath = (str: string) => {
        if (cyInstance === null) return;
        /* cyInstance?.$(`node#${str}`).style({"overlay-color": "red", "overlay-opacity": "0.5", "overlay-padding": "5px"}) */
        const nodeSelectors = str.split("\n").map((n: string) => `node#${n}`).join(',')
        cyInstance?.$(`node.path_node`).removeClass("path_node")
        cyInstance?.$(`${nodeSelectors}`).addClass("path_node")
    }



    /*     const graphElements = Maps.straightLineMap; */
    const graphElements = Maps.roughMap;

    return (
        <Container className="my-5 main-container">
            <Row className="justify-content-center">
                <Col>
                    <h1 className="text-center mb-4">Round Trip MD</h1>
                    <LoadingOverlay
                        active={isSearchingForPath}
                        spinner
                        text='Searching for best path...'
                    >
                        <div style={{padding: "10px"}}>
                            <Form>
                                <Form.Group controlId="startingLocation" className="mb-3">
                                    <Form.Label>Starting Location:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={startingLocation}
                                        onChange={(e) => {
                                            highlightStart(e.target.value)
                                            setStartingLocation(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="endingLocation" className="mb-3">
                                    <Form.Label>Ending Location:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={endingLocation}
                                        onChange={(e) => {
                                            highlightEnd(e.target.value)
                                            setEndingLocation(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="wards">
                                    <Form.Label>Enter the wards you have to visit (one per line):</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Example:&#10;W45&#10;W53&#10;W43"
                                        rows={5}
                                        value={inputWards}
                                        onChange={(e) => {
                                            highlightPath(e.target.value)
                                            setInputWards(e.target.value)}}
                                    />
                                </Form.Group>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={RenderTooltip}
                                >
                                    <span className="d-inline-block">
                                        <Button
                                            variant="primary"
                                            onClick={handleSubmit}
                                            className="mt-2"
                                            disabled={!startingLocation || !endingLocation || !inputWards}
                                        >
                                            Find Best Route
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </Form>
                        </div>
                    </LoadingOverlay>

                    {route.length > 0 && (
                        <Alert variant="success" className="mt-4">
                            <Alert.Heading>Shortest Route</Alert.Heading>
                            <p>{route.join(' -> ')}</p>
                            <p>Execution time: {executionTime} ms</p>
                        </Alert>
                    )}
                    {routeErrors.length > 0 && (
                        <Alert variant="danger" className="mt-4">
                            <Alert.Heading>Routing cancelled. Some wards were not found in the map:</Alert.Heading>
                            <p>{routeErrors.join(', ')}</p>
                        </Alert>
                    )}
                    {uniqueError && (
                        <Alert variant="danger" className="mt-4">
                            <Alert.Heading>You have requested for a route with duplicate wards / locations. </Alert.Heading>
                            <p>This is not supported - please remove any duplicates.</p>
                        </Alert>
                    )}




                    <h2 className="text-center mb-3">Hospital Map</h2>
                    <div className="mt-4 border border-3 rounded p-3">

                        <div className="mt-3 d-flex justify-content-between">
                            <Button variant="secondary" onClick={resetGraph}>
                                Reset Graph
                            </Button>
                            <div>
                                <Button variant="secondary" onClick={zoomOut} className="me-2">
                                    Zoom Out
                                </Button>
                                <Button variant="secondary" onClick={zoomIn}>
                                    Zoom In
                                </Button>
                            </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            <div className="legend-box red-box"></div> Starting point
                            <div className="legend-box green-box ms-3"></div> Ending point
                            <div className="legend-box blue-box ms-3"></div> Required points to visit
                        </div>
                        <CytoscapeComponent
                            elements={graphElements}
                            style={{ width: '100%', height: '1000px' }}
                            stylesheet={[
                                {
                                    selector: 'node',
                                    style: {
                                        'background-color': '#666',
                                        'label': 'data(id)'
                                    }
                                },
                                {
                                    selector: '.starting_node',
                                    style: {
                                        'overlay-color': 'red',
                                        'overlay-opacity': '0.5',
                                        "overlay-padding": "5px"
                                    }
                                },
                                {
                                    selector: '.ending_node',
                                    style: {
                                        'overlay-color': 'green',
                                        'overlay-opacity': '0.5',
                                        "overlay-padding": "5px"
                                    }
                                },
                                {
                                    selector: '.path_node',
                                    style: {
                                        'overlay-color': 'blue',
                                        'overlay-opacity': '0.5',
                                        "overlay-padding": "5px"
                                    }
                                },
                                {
                                    selector: 'edge',
                                    style: {
                                        'width': 3,
                                        'line-color': '#ccc',
                                        'target-arrow-color': '#ccc',
                                        'target-arrow-shape': 'triangle',
                                        'curve-style': 'bezier',
                                        'label': 'data(weight)'
                                    }
                                }                        ]}
                            layout={currentLayout}
                            wheelSensitivity={0.1}
                            cy={(cy: cytoscape.Core) => {
                                // Add your cytoscape configuration here
                                setCyInstance(cy);
                                setInitialZoom(cy.zoom());
                                highlightStart(startingLocation)
                                highlightEnd(endingLocation)
                                highlightPath(inputWards)
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default App;
